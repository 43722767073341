import React from 'react';
import Link from '../TransitionLink';
import { useFormatDate } from '../../hooks/date';

/* Stand-alone */
const CardText = ({
  label,
  title,
  date,
  url
}) => {
  return (
    <Link to={`${url}/`} className="card-text--anchor">
      <article className="card-text card-text--with-shadow">
        <CardInner
          label={label}
          title={title}
          date={date}
        />
      </article>
    </Link>
  );
};

/* To insert inside another component */
export const CardTextInner = ({
  label, title, date
}) => {
  return (
    <div className="card-text">
      <CardInner
        label={label}
        title={title}
        date={date}
      />
    </div>
  );
};

const CardInner = ({
  label,
  title,
  date
}) => {
  return (
    <div className="card-text__container">
      <span className="card-text__label label-small">{label}</span>
      <h3 className="card-text__title heading-xs">{title}</h3>
      <span className="card-text__date label-small">{useFormatDate(date)}</span>
    </div>
  );
};

export default CardText;
